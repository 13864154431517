.zds-slide-bottom-enter {
  position: absolute;
  transform: translateY(100%);
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.47, 0.65, 0.61, 0.9);
  animation-play-state: paused; }

.zds-slide-bottom-enter.zds-slide-bottom-enter-active {
  -webkit-animation-name: zdsSlideBottomEnter;
  animation-name: zdsSlideBottomEnter;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zds-slide-bottom-leave {
  position: absolute;
  transform: translateY(100%);
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.zds-slide-bottom-leave.zds-slide-bottom-leave-active {
  -webkit-animation-name: zdsSlideBottomLeave;
  animation-name: zdsSlideBottomLeave;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

@keyframes zdsSlideBottomEnter {
  0% {
    transform: translateY(100%); }
  100% {
    transform: translateY(0); } }

@keyframes zdsSlideBottomLeave {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(100%); } }

.zds-slide-top-enter {
  position: absolute;
  transform: translateY(-100%);
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.47, 0.65, 0.61, 0.9);
  animation-play-state: paused; }

.zds-slide-top-enter.zds-slide-top-enter-active {
  -webkit-animation-name: zdsSlideTopEnter;
  animation-name: zdsSlideTopEnter;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zds-slide-top-leave {
  position: absolute;
  transform: translateY(0);
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.zds-slide-top-leave.zds-slide-top-leave-active {
  -webkit-animation-name: zdsSlideTopLeave;
  animation-name: zdsSlideTopLeave;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

@keyframes zdsSlideTopEnter {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

@keyframes zdsSlideTopLeave {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-100%); } }

.zds-slide-left-enter {
  position: absolute;
  transform: translateX(-100%);
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.47, 0.65, 0.61, 0.9);
  animation-play-state: paused; }

.zds-slide-left-enter.zds-slide-left-enter-active {
  -webkit-animation-name: zdsSlideLeftEnter;
  animation-name: zdsSlideLeftEnter;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zds-slide-left-leave {
  position: absolute;
  transform: translateX(0);
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.zds-slide-left-leave.zds-slide-left-leave-active {
  -webkit-animation-name: zdsSlideLeftLeave;
  animation-name: zdsSlideLeftLeave;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

@keyframes zdsSlideLeftEnter {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0); } }

@keyframes zdsSlideLeftLeave {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

.zds-slide-right-enter {
  position: absolute;
  transform: translateX(100%);
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.47, 0.65, 0.61, 0.9);
  animation-play-state: paused; }

.zds-slide-right-enter.zds-slide-right-enter-active {
  -webkit-animation-name: zdsSlideRightEnter;
  animation-name: zdsSlideRightEnter;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zds-slide-right-leave {
  position: absolute;
  transform: translateX(0);
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.zds-slide-right-leave.zds-slide-right-leave-active {
  -webkit-animation-name: zdsSlideRightLeave;
  animation-name: zdsSlideRightLeave;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

@keyframes zdsSlideRightEnter {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0); } }

@keyframes zdsSlideRightLeave {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

.zds-fade-enter {
  opacity: 0;
  animation-duration: .2s;
  animation-fill-mode: both;
  animation-play-state: paused; }

.zds-fade-enter.zds-fade-enter-active {
  -webkit-animation-name: zdsFadeIn;
  animation-name: zdsFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zds-fade-leave {
  opacity: 0;
  animation-duration: .2s;
  animation-fill-mode: forwards;
  animation-play-state: paused; }

.zds-fade-leave.zds-fade-leave-active {
  -webkit-animation-name: zdsFadeOut;
  animation-name: zdsFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

@keyframes zdsFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes zdsFadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.zds-zoom-enter {
  opacity: 0;
  transform: scale(0.3);
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-play-state: paused; }

.zds-zoom-enter.zds-zoom-enter-active {
  -webkit-animation-name: zdsZoomIn;
  animation-name: zdsZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.zds-zoom-leave {
  animation-duration: .3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  animation-play-state: paused; }

.zds-zoom-leave.zds-zoom-leave-active {
  -webkit-animation-name: zdsZoomOut;
  animation-name: zdsZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running; }

@keyframes zdsZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.3); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes zdsZoomOut {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(0.3); } }

.zds-switch {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 46px;
  height: 24px;
  line-height: 22px;
  padding: 0;
  vertical-align: middle;
  border-radius: 22px 22px;
  border: 1px solid #C4CFEA;
  background-color: #C4CFEA;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1); }

.zds-switch::after {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 3px;
  top: 2px;
  border-radius: 50% 50%;
  background-color: #fff;
  content: " ";
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transform: scale(1);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
  animation-duration: 0.3s;
  animation-name: SwitchOff; }

.zds-switch:active::after {
  transform: scale(1.1);
  animation-name: SwitchOn; }

.zds-switch-inner {
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 24px;
  top: 0; }

.zds-switch-checked {
  border: 1px solid #2871F7;
  background-color: #2871F7; }

.zds-switch-checked .zds-switch-inner {
  left: 6px; }

.zds-switch-checked::after {
  left: 23px; }

.zds-switch-disabled {
  cursor: no-drop;
  background: #E2E4EA;
  border-color: #E2E4EA; }

.zds-switch-disabled:after {
  animation-name: none;
  cursor: no-drop; }

.zds-switch-disabled:active::after {
  transform: scale(1);
  animation-name: none; }

.zds-switch-disabled.zds-switch-checked {
  background: #c6d7f7;
  border-color: #c6d7f7; }

@keyframes SwitchOn {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(1.25); }
  100% {
    transform: scale(1.1); } }

@keyframes SwitchOff {
  0% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }
